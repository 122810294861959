import { ASSETS } from '@/assets';
import AnchorInfoModel from '@/compnents/anchorInfo/model';
import { type BaseData, MainSubStructureModel, RenderByPermission, request, requireSource } from '@/utils';
import { Button, Modal, Tag, message } from 'antd';
import dayjs from 'dayjs';
import { observable } from 'mobx';
import { API } from './api';
import EditUserModel from './modal/editUserModel';

export default class WhiteListModel {
  constructor() {
    this.whiteListStore.onQuery();
  }

  @observable public editUserStore: EditUserModel = new EditUserModel({
    parent: this,
  });
  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框

  public whiteListStore: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增白名单',
        permissionId: '2',
        handleClick: () => {
          this.editUserStore.onShow('add');
        },
      },
    ],
    grid: {
      columns: [
        {
          name: '操作',
          key: 'operations',
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.editUserStore.onShow('edit', row);
                    }}
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.onDeleteCategoryHandler(row.id);
                    }}
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },

        {
          key: 'userInfo',
          name: '用户信息',
          width: 250,
          formatter: ({ row }) => {
            const { userId, mobile, userAvatar, userNickName, userName, isFree } = row;

            return (
              <div className={'flex flex-row items-center h-full'}>
                <div className="flex flex-col">
                  <div
                    style={{
                      height: '80px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', fontSize: '12px', lineHeight: '20px' }}>
                      用户昵称：{userName || userNickName}
                    </div>
                    <div style={{ fontSize: '12px', lineHeight: '20px' }}>ID：{userId}</div>
                    <div style={{ fontSize: '12px', lineHeight: '20px' }}>手机号：{mobile}</div>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          name: '用途',
          key: 'useName',
          formatter: ({ row }) => {
            const { whitelistUseList } = row;
            if (whitelistUseList.length) {
              return whitelistUseList.map((v) => {
                return <Tag key={v.id}>{v.useName}</Tag>;
              });
            }
            return '';
          },
        },
        {
          name: '创建人',
          key: 'create',
          formatter: ({ row }) => {
            const { createUserId, createUserMobile, createUserName } = row;
            return (
              <div style={{ height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>用户昵称：{createUserName}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>ID：{createUserId}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>手机号：{createUserMobile}</div>
              </div>
            );
          },
        },
        {
          name: '创建时间',
          key: 'createTime',
          formatter: ({ row }) => {
            const { createTime } = row;
            return createTime ? dayjs(createTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rowHeight: 80,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { ...rest } = params;
        return request<BaseData<any>>({
          url: API.whitelist,
          method: 'post',
          data: { ...rest },
        });
      },
    },
  });

  private onDeleteCategoryHandler = (id: string) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除该白名单用户吗',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({ url: API.delete, method: 'post', data: { id: id } });
        message.success('删除成功');
        this.whiteListStore.onQuery();
      },
    });
  };
}
